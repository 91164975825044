import React from "react"
import styled from "@emotion/styled"
import "flickity/css/flickity.css"
import { injectIntl, Link } from "gatsby-plugin-intl"

const ResultHomeSection = ({ intl, data, page, id }) => {
  return (
    <ResultHomeSectionWrapper
      id={id}
      className={id ? "lyneup-page-section" : ""}
    >
      <InnovationContentWrapper page={page}>
        {data.title && (
          <SectionTitle dangerouslySetInnerHTML={{ __html: data.title }} />
        )}
        {data.text && (
          <MobileSectionText dangerouslySetInnerHTML={{ __html: data.text }} />
        )}
        {data.list && (
          <ContentWrapper>
            <LeftContentBox>
              {data.list.map((item, index) => {
                return (
                  <ContentBox key={index.toString()}>
                    <TextBox key={index.toString()}>
                      {item.title && (
                        <Title
                          dangerouslySetInnerHTML={{ __html: item.title }}
                        />
                      )}
                      {item.text && (
                        <Text dangerouslySetInnerHTML={{ __html: item.text }} />
                      )}
                    </TextBox>
                  </ContentBox>
                )
              })}
            </LeftContentBox>
            {data.text && (
              <RightContentBox>
                {data.text && (
                  <DesktopSectionText
                    dangerouslySetInnerHTML={{ __html: data.text }}
                  />
                )}
                {data.bottom_text && (
                  <DesktopBottomText
                    dangerouslySetInnerHTML={{ __html: data.bottom_text }}
                  />
                )}
              </RightContentBox>
            )}
          </ContentWrapper>
        )}
        {data.bottom_text && (
          <MobileBottomText
            dangerouslySetInnerHTML={{ __html: data.bottom_text }}
          />
        )}
        {data.button_url && data.button_text && (
          <ButtonWrapper>
            <ProductButton to={data.button_url}>
              {data.button_text}
            </ProductButton>
          </ButtonWrapper>
        )}
      </InnovationContentWrapper>
    </ResultHomeSectionWrapper>
  )
}

const ResultHomeSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px;
  background: #fff;
  position: relative;
  align-items: center;
  justify-content: center;
`

const InnovationContentWrapper = styled.div`
  width: 100%;
  max-width: 1080px;
  padding: 20px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 550px) {
    align-items: flex-start;
    padding: ${({ page }) =>
      page === "lyneup" ? "0px 20px 20px" : "40px 20px 20px"};
  }
`

const SectionTitle = styled.h2`
  font-size: 40px;
  line-height: 1;
  font-family: "BebasNeueBold";
  font-weight: bold;
  color: #262626;
  margin-bottom: 0px;

  @media (max-width: 850px) {
    font-size: 36px;
  }

  span {
    @media (max-width: 550px) {
      display: block;
    }
  }
`

const MobileSectionText = styled.div`
  display: none;
  font-size: 20px;
  line-height: 28px;
  font-family: "Museo";
  font-weight: 500;
  color: #262626;
  margin-top: 25px;

  span {
    background: linear-gradient(
      90deg,
      rgb(165, 250, 183),
      rgb(0, 196, 193),
      rgb(80, 181, 213)
    );
    padding: 4px 5px 2px;
  }

  @media (max-width: 550px) {
    display: block;
  }
`

const DesktopSectionText = styled.div`
  font-size: 18px;
  line-height: 26px;
  font-family: "Museo";
  font-weight: 500;
  color: #262626;
  margin: 10px 0px;

  span {
    background: linear-gradient(
      90deg,
      rgb(165, 250, 183),
      rgb(0, 196, 193),
      rgb(80, 181, 213)
    );
    padding: 7px 5px 3px;

    @media (max-width: 850px) {
      padding: 4px 5px 2px;
    }
  }

  @media (max-width: 550px) {
    display: none;
  }
`

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  margin: 20px 0px;

  @media (max-width: 550px) {
    margin: 25px 0px 0px;
    flex-direction: column;
  }
`

const LeftContentBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-right: 25px;

  @media (max-width: 850px) {
    padding-right: 10px;
  }

  @media (max-width: 550px) {
    justify-content: flex-start;
  }
`

const RightContentBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-left: 25px;

  @media (max-width: 850px) {
    padding-left: 10px;
  }

  @media (max-width: 550px) {
    justify-content: flex-start;
  }
`

const ContentBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 10px 0px;

  @media (max-width: 550px) {
    justify-content: flex-start;
  }
`

const TextBox = styled.div`
  color: #262626;
`

const Title = styled.div`
  font-size: 80px;
  line-height: 1;
  font-family: "Museo";
  font-weight: 700;
  color: #262626;
  background: -webkit-linear-gradient(#a5fab7, #00c4c1, #50b5d5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: 550px) {
    font-size: 64px;
  }
`

const Text = styled.div`
  font-size: 16px;
  line-height: 1.2;
  font-family: "Museo";
  font-weight: 700;
  color: #262626;
`

const MobileBottomText = styled.div`
  display: none;
  font-size: 12px;
  line-height: 1.2;
  font-family: "Gotham Book";
  color: #262626;
  margin: 25px 0px;

  @media (max-width: 550px) {
    display: block;
  }
`

const DesktopBottomText = styled.div`
  font-size: 13px;
  line-height: 1.2;
  font-family: "Gotham Book";
  color: #262626;
  margin: 10px 0px;

  @media (max-width: 550px) {
    display: none;
  }
`

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;
`

const ProductButton = styled(Link)`
  padding: 12px 20px;
  color: #262626;
  font-family: "Museo";
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 1px solid ${props => props.theme.colors.border.blue};
  border-radius: 25px 0;

  :hover {
    background: ${props => props.theme.gradient.buttonGradient1};
    border: 0px;
  }

  @media (max-width: 550px) {
    width: 100%;
  }
`

export default injectIntl(ResultHomeSection)
